<template>
  <div class="text-center d-flex">
    <v-menu
      bottom
      :close-on-content-click="closeOnContentClick"
      width="300"
      offset-y
      z-index="1"
      allow-overflow
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          hide-details
          height="10"
          class="caption"
          prepend-inner-icon="mdi-filter"
          :placeholder="$_strings.order.FILTER"
          outlined
          dense
          readonly
          v-bind="attrs"
          @click="closeOnContentClick = false"
          v-on="on"
        ></v-text-field>
      </template>
      <v-list
        width="300"
        height="500"
        class="overflow-auto"
      >
        <section v-for="item in filterItems" :key="item.id">
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">{{item.title}}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="item.type === 'textfield'">
            <v-list-item-content class="pt-0 mt-0">
              <v-text-field
                hide-details
                class="caption"
                outlined
                dense
                v-model="form[item.vModel]"
                v-mask="item.mask ? {'alias': item.mask, allowMinus: false, rightAlign: false} : ''"
                clearable
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.type === 'approvalStatus'">
            <v-list-item-content class="pt-0 mt-0">
              <section
                v-for="(chip, index) in tools.approvalStatus"
                :key="chip"
                class="mb-2"
              >
                <v-chip
                  close
                  small
                  @click:close="removeApprovalStatus(index)"
                >
                  <strong>{{ approvalStatusItems.find((item) => item.value === chip) && approvalStatusItems.find((item) => item.value === chip).text }}</strong>
                </v-chip>
              </section>
              <v-autocomplete
                hide-details
                :items="approvalStatusItems.filter((item) => !(tools.approvalStatus.find((status) => status === item.value)))"
                @change="addApprovalStatus"
                v-model="tempApprovalStatus"
                class="caption"
                placeholder="Pilih status"
                dense
              ></v-autocomplete>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.type === 'activeStatus'">
            <v-list-item-content class="pt-0 mt-0">
              <section
                v-for="(chip, index) in tools.activeStatus"
                :key="chip"
                class="mb-2"
              >
                <v-chip
                  close
                  small
                  @click:close="removeActiveStatus(index)"
                >
                  <strong>{{ activeStatusItems.find((item) => item.value === chip) && activeStatusItems.find((item) => item.value === chip).text }}</strong>
                </v-chip>
              </section>
              <v-autocomplete
                hide-details
                :items="activeStatusItems.filter((item) => !(tools.activeStatus.find((status) => status === item.value)))"
                @change="addActiveStatus"
                v-model="tempActiveStatus"
                class="caption"
                placeholder="Pilih status"
                dense
              ></v-autocomplete>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section class="pl-2 pr-2">
          <v-btn
            depressed
            color="primary"
            block
            @click="setFilter"
          >
            {{$_strings.common.APPLY}}
          </v-btn>
          <v-btn
            depressed
            class="mt-2 white--text"
            color="red"
            block
            @click="closeOnContentClick = true"
          >
            {{$_strings.common.CLOSE}}
          </v-btn>
        </section>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
  },
  data() {
    return {
      filterItems: [
        {
          id: 'moId',
          title: 'Nomor MoU',
          vModel: 'mouNo',
          type: 'textfield',
        },
        {
          id: 'abbreviation',
          title: 'Alias / Inisial',
          vModel: 'abbreviation',
          type: 'textfield',
        },
        {
          id: 'mouPeriodMonths',
          title: 'Durasi Mou',
          vModel: 'mouPeriodMonths',
          mask: 'integer',
          type: 'textfield',
        },
        {
          id: 'monthsExpired',
          title: 'Durasi Expired Mou',
          vModel: 'monthsExpired',
          mask: 'integer',
          type: 'textfield',
        },
        {
          id: 'activeStatus',
          title: 'Status',
          vModel: 'activeStatus',
          type: 'activeStatus',
        },
        {
          id: 'approvalStatus',
          title: 'Status Persetujuan',
          vModel: 'approvalStatus',
          type: 'approvalStatus',
        },
      ],
      form: {
        mouNo: this.$route.query.mouNo || '',
        abbreviation: this.$route.query.abbreviation || '',
        mouPeriodMonths: this.$route.query.mouPeriodMonths || '',
        monthsExpired: this.$route.query.monthsExpired || '',
        activeStatus: this.$route.query.activeStatus || '',
        approvalStatus: this.$route.query.approvalStatus || '',
      },
      tools: {
        activeStatus: this.$route.query.activeStatus ? this.$route.query.activeStatus.split(',') : [],
        approvalStatus: this.$route.query.approvalStatus ? this.$route.query.approvalStatus.split(',') : [],
      },
      tempApprovalStatus: '',
      tempActiveStatus: '',
      approvalStatusItems: [
        {
          text: 'Menunggu Persetujuan',
          value: 'MENUNGGU_PERSETUJUAN',
        },
        {
          text: 'Disetujui',
          value: 'DISETUJUI',
        },
        {
          text: 'Revisi',
          value: 'REVISI',
        },
        {
          text: 'Ditolak',
          value: 'DITOLAK',
        },
      ],
      activeStatusItems: [
        {
          text: 'Menunggu Aktif',
          value: 'WAITING_ACTIVE',
        },
        {
          text: 'Draft',
          value: 'DRAFT',
        },
        {
          text: 'Aktif',
          value: 'ACTIVE',
        },
        {
          text: 'Tidak Aktif',
          value: 'INACTIVE',
        },
        {
          text: 'Expired',
          value: 'EXPIRED',
        },
      ],
      closeOnContentClick: false,
    };
  },
  computed: {
    mouBy() {
      const [,,, path] = this.$route.path.split('/');
      return path;
    },
  },
  watch: {
    mouBy() {
      this.form = {
        mouNo: '',
        abbreviation: '',
        mouPeriodMonths: '',
        monthsExpired: '',
        activeStatus: '',
        approvalStatus: '',
      };
      this.tempApprovalStatus = '';
      this.tempActiveStatus = '';
      this.tools.activeStatus = [];
      this.tools.approvalStatus = [];
    },
  },
  methods: {
    addApprovalStatus(value) {
      this.tools.approvalStatus.push(value);
      this.form.approvalStatus = this.tools.approvalStatus.toString(',');
    },
    removeApprovalStatus(index) {
      this.tools.approvalStatus.splice(index, 1);
      this.form.approvalStatus = this.tools.approvalStatus.toString(',');
      this.tempApprovalStatus = '';
    },
    addActiveStatus(value) {
      this.tools.activeStatus.push(value);
      this.form.activeStatus = this.tools.activeStatus.toString(',');
    },
    removeActiveStatus(index) {
      this.tools.activeStatus.splice(index, 1);
      this.form.activeStatus = this.tools.activeStatus.toString(',');
      this.tempActiveStatus = '';
    },
    setFilter() {
      this.closeOnContentClick = true;
      const {
        mouNo,
        abbreviation,
        mouPeriodMonths,
        monthsExpired,
        activeStatus,
        approvalStatus,
      } = this.form;
      const oldFilters = { ...this.filters };
      this.filters.mouNo = mouNo;
      this.filters.abbreviation = abbreviation;
      this.filters.mouPeriodMonths = mouPeriodMonths;
      this.filters.monthsExpired = monthsExpired;
      this.filters.activeStatus = activeStatus;
      this.filters.approvalStatus = approvalStatus;
      if (JSON.stringify(oldFilters) !== JSON.stringify(this.filters)) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...this.filters,
            page: 1,
          },
        });
      }
      this.$emit('fetchMoUList');
    },
  },
};
</script>
