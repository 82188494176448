<template>
  <section>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="items"
      item-key="id"
      :server-items-length="totalItems"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      @dblclick:row="dblClick"
    >
      <template v-slot:[`item.mouPeriodMonths`]="{item}">
        <span>
          {{item.mouPeriodMonths}} Bulan
        </span>
        <br>
        <span class="text-caption">
          {{`${dateFormat(item.mouPeriodStart)} sd ${dateFormat(item.mouPeriodEnd)}`}}
        </span>
      </template>
      <template v-slot:[`item.createdBy`]="{item}">
        <span>
          {{item.createdBy}}
        </span>
        <br>
        <span class="text-caption">
          {{item.createdAt}}
        </span>
      </template>
      <template v-slot:[`item.approvalStatus`]="{item}">
        <v-btn
          text
          small
          class="text-decoration-underline"
          color="red"
          :class="checkStatus(item.approvalStatus)"
          @click="onClickApprovalStatus(item)"
        >
          {{item.approvalStatus}}
        </v-btn>
      </template>
      <template v-slot:[`item.actionColumn`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="routeNavigate(item, { action: 'view' })"
              icon
              color="primary">
              <v-icon small>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.VIEW_DETAIL}}</span>
        </v-tooltip>
        <v-tooltip
          v-if="(item.canEdit && item.activeStatus === 'DRAFT') || (item.canEdit && item.activeStatus === 'ACTIVE') && userAccess.canUpdate"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="clickedEdit(item)"
              color="primary">
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span v-if="item.activeStatus === 'DRAFT'">{{$_strings.common.EDIT}}</span>
          <span v-else>{{$_strings.mou.ADDENDUM}}</span>
        </v-tooltip>
        <v-tooltip
          v-if="((item.canEdit && item.activeStatus === 'DRAFT') || item.activeStatus === 'WAITING_ACTIVE')&& userAccess.canDelete"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="showDialogDeleteConfirm(item)"
              icon
              color="primary">
              <v-icon small>
                mdi-trash-can
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.DELETE}}</span>
        </v-tooltip>
        <v-tooltip
          v-if="item.activeStatus === 'ACTIVE' && !item.isDownload"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :loading="item.donwloading"
              @click="downloadMou(item)"
              icon
              color="primary">
              <v-icon small>
                mdi-download
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.DOWNLOAD}}</span>
        </v-tooltip>
        <v-tooltip
          v-if="item.activeStatus === 'ACTIVE' && !item.isUploading"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              dark
              icon
              x-small
              @click="$refs['file'+item.id].$refs.input.click()"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
            <v-file-input
              class="d-none"
              :label="$_strings.userAdmin.IMAGE_INPUT_LABEL"
              :clearable="false"
              :ref="'file'+item.id"
              type="file"
              accept=".pdf"
              @change="($event) => setFileInput($event, item)"
            />
          </template>
          <span>{{ $_strings.contract.tooltips.UPLOAD }}</span>
        </v-tooltip>
        <v-tooltip
          v-if="item.activeStatus === 'ACTIVE' && !item.isDownload"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :loading="item.downloadTemp"
              @click="downloadMouTemplate(item)"
              icon
              color="primary">
              <v-icon small>
                mdi-file-download-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Download Template</span>
        </v-tooltip>
        <v-progress-circular
          class="ml-2"
          v-if="item.isUploading"
          :value="item.progressUploading"
          color="primary"
          size="18"
        ></v-progress-circular>
        <v-tooltip
          v-if="item.activeStatus === 'ACTIVE' && mouBy === 'shipper'"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="routeNavigate(item, { action: 'set-vehicle-number' }, 2)"
              icon
              color="primary">
              <v-icon small>
                mdi-car-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.mou.SET_VEHICLE_NUMBER}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          {{$_strings.mou.MOU_SHIPPER}}
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
    <Dialog ref="confirm" />
    <DialogApprovalHistory
      ref="dialogApprovalHistory"
    />
  </section>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import DialogApprovalHistory from './Dialog/ApprovalHistory';
import Dialog from '../ConfirmationDialog/Dialog.vue';

export default {
  name: 'table-data-list',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    mouBy: {
      type: String,
      default: '',
    },
  },
  components: {
    DialogApprovalHistory,
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      historyList: [],
      dialogApprovalHistory: false,
      sortBy: 'mouNo',
      desc: [false],
      items: [],
      headers: [
        {
          text: this.$_strings.mou.MOU_NUMBER_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          cellClass: 'clickable',
          value: 'mouNo',
          width: '14vw',
        },
        {
          text: this.$_strings.mou.COMPANY_NAME_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          cellClass: 'clickable',
          value: 'companyName',
          width: '12vw',
        },
        {
          text: this.$_strings.mou.ALIAS_INITIAL_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          cellClass: 'clickable',
          value: 'abbreviation',
          width: '9vw',
        },
        {
          text: this.$_strings.mou.MOU_DURATION_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          value: 'mouPeriodMonths',
          width: '14vw',
        },
        {
          text: this.$_strings.mou.MOU_EXPIRED_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          value: 'monthsExpired',
          width: '9vw',
        },
        {
          text: this.$_strings.mou.CREATED_BY_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          cellClass: 'clickable',
          value: 'createdBy',
          width: '10vw',
        },
        {
          text: this.$_strings.mou.STATUS_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          cellClass: 'clickable',
          value: 'activeStatus',
          width: '7vw',
        },
        {
          text: this.$_strings.mou.APPROVAL_STATUS_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          cellClass: 'clickable',
          value: 'approvalStatus',
          width: '12vw',
        },
        {
          text: this.$_strings.mou.ACTION_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          value: 'actionColumn',
          align: 'left',
          width: '12vw',
          sortable: false,
        },
      ],
      pagination: this.defaultPagination(),
      totalItems: 0,
    };
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    canAddendum() {
      if (this.mouBy === 'shipper') return this.menuFunctions.ADDENDUM_MOU_SHIPPER;
      return this.menuFunctions.ADDENDUM_MOU_TRANSPORTER;
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchMoUList();
      },
      deep: true,
    },
    currentPath() {
      this.items = [];
      this.pagination = {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      };
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    toggleLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    dblClick(event, { item }) {
      this.routeNavigate(item, { action: 'view' });
    },
    checkStatus(obj) {
      if (obj) {
        if (obj === 'Disetujui') {
          return 'green--text';
        }
        if (obj === 'Ditolak') {
          return 'red--text';
        }
        return 'orange--text';
      }
    },
    clickedEdit(item) {
      const { workflowId, activeStatus, mouType } = item;
      if (typeof +workflowId !== 'number'
        || (activeStatus === 'DRAFT' && mouType === 'MOU')
      ) {
        return this.routeNavigate(item, { action: 'edit' });
      }
      this.routeNavigate(item, { action: 'addendum' });
    },
    async showDialogDeleteConfirm(item) {
      if (await
      this.$refs.confirm.open(
        'Konfirmasi',
        'Apakah anda yakin akan menghapus MoU ini?',
      )
      ) {
        this.deleteMou(item);
      }
    },
    async deleteMou(item) {
      const service = this.mouBy === 'transporter' ? this.$_services.mou.deleteMouTransporter : this.$_services.mou.deleteMouShipper;
      try {
        this.$root.$loading.show();
        await service(item.id);
        this.$dialog.notify.success('MoU berhasil dihapus');
        this.fetchMoUList();
      } finally {
        this.$root.$loading.hide();
      }
    },
    async fetchMoUList(page) {
      const service = this.mouBy === 'transporter' ? this.$_services.mou.fetchMouTransporter : this.$_services.mou.fetchMouShipper;
      this.toggleLoading(true);
      const _page = (page || this.pagination.page) - 1;
      const size = this.pagination.itemsPerPage;
      const sort = this.handleSortBy({ sortBy: this.pagination.sortBy, sortDesc: this.pagination.sortDesc });
      const filters = {
        page: _page,
        size,
        sort,
        ...this.filters,
      };
      try {
        const res = await service(this.skipEmptyStringObject(filters));
        this.items = [];
        this.totalItems = 0;
        if (res.data.contents) {
          res.data.contents.forEach((el) => {
            if (el.approvalStatus) {
              const statusApprovalList = this.$_strings.contract.statusApproval;
              const statusApproval = statusApprovalList.find((d) => d.key === el.approvalStatus);
              el.approvalStatus = statusApproval.value;
            }
            el.monthsExpired = el.monthsExpired ? `${el.monthsExpired} Bulan` : '-';
            if (el.canEdit === false && el.activeStatus === 'DRAFT') {
              el.approvalStatus = 'Menunggu Persetujuan';
              el.activeStatus = 'INACTIVE';
            }
          });
          this.items = res.data.contents;
          this.totalItems = res.data.totalData;
        }
      } finally {
        this.toggleLoading(false);
      }
    },
    fileUploadValidation(event, fileType) {
      if (!event) return;
      const { size } = event;
      // 7168000 => 7MB
      const fileTypeSupport = ['pdf'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > 7168000) {
        this.$dialog.notify.error('File Maksimum 7MB.');
        return false;
      }
      return true;
    },
    setFileInput(file, item) {
      const fileType = file.name.split('.').pop().toLowerCase();
      const valid = this.fileUploadValidation(file, fileType);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const encodedFile = e.target.result;
        const fileName = file.name.replaceAll(' ', '_');
        const form = {
          fileName,
          encodedFile,
        };
        this.uploadMou(item, form);
      };
    },
    DownloadFile(item, fileName = 'mou.pdf', fileUrl) {
      const req = new XMLHttpRequest();
      req.open('GET', fileUrl, true);
      req.responseType = 'blob';
      req.onload = () => {
        const blob = new Blob([req.response], { type: 'application/octetstream' });
        // Check the Browser type and download the File.
        const isIE = false || !!document.documentMode;
        if (isIE) {
          window.navigator.msSaveBlob(blob, fileName);
          this.$delete(item, 'donwloading');
        } else {
          const url = window.URL || window.webkitURL;
          const link = url.createObjectURL(blob);
          const a = document.createElement('a');
          a.setAttribute('download', fileName);
          a.setAttribute('href', link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.$delete(item, 'donwloading');
        }
      };
      req.send();
    },
    async downloadMou(item) {
      try {
        this.$set(item, 'donwloading', true);
        let response;
        if (this.mouBy === 'transporter') {
          response = await this.$_services.mou.downloadMouTransporter(item.id);
        } else {
          response = await this.$_services.mou.downloadMouShipper(item.id);
        }
        const fileExtension = response.data.split('.').pop();
        if (!fileExtension) {
          window.open(response.data);
          return;
        }
        const fileName = `mou.${fileExtension}`;
        const url = response.data;
        this.DownloadFile(item, fileName, url);
      } catch {
        this.$delete(item, 'donwloading');
      }
    },
    async downloadMouTemplate(item) {
      try {
        this.$set(item, 'downloadTemp', true);
        const config = {
          responseType: 'blob',
        };
        let response;
        if (this.mouBy === 'transporter') {
          response = await this.$_services.mou.downloadMouTempTransporter(item.id, config);
        } else {
          response = await this.$_services.mou.downloadMouTempShipper(item.id, config);
        }
        const blob = new Blob([response.data], { type: 'application/docx' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const { mouNo } = item;
        const invoiceLink = mouNo.replace(/\//g, '_');
        link.download = `mouTemplate-${invoiceLink}.docx`;
        link.click();
      } finally {
        this.$delete(item, 'downloadTemp');
      }
    },
    async uploadMou(item, form) {
      const mouId = item.id;
      try {
        this.$set(item, 'isUploading', true);
        this.$set(item, 'progressUploading', 1);
        const self = this;
        const config = {
          onUploadProgress(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            self.$set(item, 'progressUploading', percentCompleted);
          },
        };
        await this.$_services.mou.mouUpload(this.mouBy, mouId, form, config);
        this.$dialog.notify.success('MoU Berhasil di Unggah');
      } finally {
        this.$delete(item, 'isUploading');
        this.$delete(item, 'progressUploading');
      }
    },
    onClickApprovalStatus(item) {
      this.$refs.dialogApprovalHistory.dialog = true;
      this.$refs.dialogApprovalHistory.isLoading = true;
      this.$_services.mou.fetchHistoryWorkFlow(item.workflowId).then((res) => {
        const historyList = res.data.contents;
        this.$refs.dialogApprovalHistory.historyList = historyList;
      }).finally(() => {
        this.$refs.dialogApprovalHistory.isLoading = false;
      });
    },
    routeNavigate(item, { action }, step = 0) {
      const params = {
        action,
      };
      let name = 'shipper-mou-detail';
      if (this.mouBy === 'transporter') name = 'transporter-mou-detail';
      if (item) params.mouId = item.id;
      this.$router.push({
        name,
        params,
        query: {
          step,
        },
      });
    },
  },
};
</script>
