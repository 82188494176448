<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
    >
      <v-card :loading="isLoading">
        <v-card-title class="body-1">
          <h4>{{$_strings.mou.APPROVAL_HISTORY}}</h4>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="displayedHeader"
            :items="historyList"
            item-key="id"
            hide-default-footer
          >
            <template v-slot:[`item.number`]=items>
              <span>{{items.index + 1}}</span>
            </template>
            <template v-slot:[`item.createDate`]="{item}">
              <span>
                {{ dateFormat(item.createDate) }}
              </span>
              <br>
              <span class="text-caption">
                {{ timeFormat(item.createDate) }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { dateFormat, timeFormat } from '../../../helper/commonHelpers';

export default {
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary',
      }));
    },
  },
  watch: {
    dialog() {
      this.historyList = [];
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: this.$_strings.contract.headerApproval.NO, value: 'number', sortable: false },
        { text: this.$_strings.contract.headerApproval.POSITION, value: 'stepName', sortable: false },
        { text: this.$_strings.contract.headerApproval.NAME, value: 'userName', sortable: false },
        { text: this.$_strings.contract.headerApproval.ACTION, value: 'action', sortable: false },
        { text: this.$_strings.contract.headerApproval.ACTION_TIME, value: 'createDate', sortable: false },
        { text: this.$_strings.contract.headerApproval.NOTE, value: 'notes', sortable: false },
      ],
      historyList: [],
      isLoading: false,
    };
  },
  methods: {
    dateFormat,
    timeFormat,
  },
};
</script>
