<template>
  <v-row dense>
    <v-col cols="auto">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="userAccess.canCreate"
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            :loading="uploadingCsv"
          >
            {{$_strings.mou.UPLOAD_CSV}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in csvItems"
            :key="index"
            @click="showDialogSelectShipper(item.value)"
          >
            <v-list-item-title for="csv-upload" v-text="item.label"></v-list-item-title>
          </v-list-item>
        </v-list>
        <input
          id="csv-upload"
          ref="fileInput"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :disabled="uploadingCsv"
          @change="uploadCsv"
          class="d-none"
        />
      </v-menu>
    </v-col>
    <v-col>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            :loading="downloadingCsv"
          >
            {{$_strings.mou.TEMPLATE_CSV}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in csvItems"
            :key="index"
            @click="downloadCsvTemplate(item.value)"
          >
            <v-list-item-title for="csv-upload" v-text="item.label"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="auto">
      <UploadedCsvStatusDialog
        :importTypeProp="$_strings.importType.MOU"
        dataType="mou"
      />
    </v-col>
    <DialogSelectMouCompany
      ref="dialogSelectShipper"
      :dataType="dataType"
      :csvItems="csvItems"
      :mouBy="mouBy"
      @handleUploadCsv="handleUploadCsv"
    />
  </v-row>
</template>

<script>
const UploadedCsvStatusDialog = () => import('../Common/UploadedCsvStatusDialog.vue');
const DialogSelectMouCompany = () => import('./Dialog/SelectMouCompany.vue');
const { exportCSVFile } = require('../../helper/csvGenerator');

export default {
  props: {
    mouBy: {
      type: String,
      default: '',
    },
  },
  components: {
    UploadedCsvStatusDialog,
    DialogSelectMouCompany,
  },
  data() {
    return {
      csvItems: [
        {
          label: 'FIXED PRICE',
          value: 'fixed_price',
        },
        {
          label: 'FCL',
          value: 'fcl',
        },
        {
          label: 'FCL BACKHAULING',
          value: 'fcl_backhauling',
        },
        {
          label: 'LCL',
          value: 'lcl',
        },
        {
          label: 'MULTIPICK/MULTIDROP',
          value: 'multiloc',
        },
      ],
      mouId: null,
      dataType: null,
      uploadingCsv: false,
      downloadingCsv: false,
    };
  },
  methods: {
    async showDialogSelectShipper(dataType) {
      this.dataType = dataType;
      this.$refs.dialogSelectShipper.dialog = true;
    },
    async handleUploadCsv(mouId) {
      this.mouId = mouId;
      await this.$refs.fileInput.click();
    },
    handleDownloadCsvDropdownClick(command) {
      this.downloadCsvTemplate(command);
    },
    async downloadCsvTemplate(dataType) {
      const convertType = {
        fixed_price: 'mou_fixed_price',
        fcl: 'mou_fcl',
        fcl_backhauling: 'mou_fcl_backhauling',
        lcl: 'mou_lcl',
        multiloc: 'mou_multi_pickup_drop',
      };
      const type = convertType[dataType];
      try {
        this.downloadingCsv = true;
        const result = await this.$_services.mou.fetchCsvTemplateMou(type, this.mouBy);
        const { column, filename } = result.data;
        exportCSVFile(null, [[...column]], filename);
      } finally {
        this.downloadingCsv = false;
      }
    },
    async uploadCsv() {
      try {
        this.uploadingCsv = true;
        const formData = new FormData();
        formData.append('file', this.$refs.fileInput.files[0]);
        formData.append('type', this.dataType);
        const services = this.dataType === 'fixed_price'
          ? this.$_services.mou.uploadCsvMouFixedPrice
          : this.$_services.mou.uploadCsvMouRoute;
        await services(formData, this.mouId, this.dataType, this.mouBy);
        this.$dialog.notify.success(this.$_strings.csvUpload.uploadCsvSuccessMessage);
        const input = this.$refs.fileInput;
        input.type = 'text';
        input.type = 'file';
        input.value = null;
      } finally {
        this.uploadingCsv = false;
      }
    },
  },
};
</script>
